<template>
  <div>
    
    <webform />
  </div>
</template>

<script>
  import Webform from '../components/Webform'

  export default {
    name: 'Home',

    components: {
      Webform,
    },
  }
</script>
