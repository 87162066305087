<template>
 
  <v-container fill-height class="registro">
    <v-row class="my-2">
      <v-col class="text-center" >
         <img src="https://sorteo.acpblindaje.mx/logo_acp.jpg" alt="" style="max-width:200px;">
      </v-col>
    </v-row>
   <v-row>
     
       <v-col class="text-center" >
           
           <v-spacer></v-spacer>
           <p>Gracias por partipar por favor descarga el QR.</p>
           <v-spacer></v-spacer>
           <p><label for=""><strong>Nombre</strong></label> :  {{ userdata.input_firstname }}</p>
            <p v-if=""><label for=""><strong>Email </strong></label>:  {{ userdata.input_email }}</p>
            <p><label for=""><strong>Telefono</strong></label> : {{ userdata.input_phone}}</p>
            <p><label for=""><strong>Codigo</strong></label>:  R-{{  userdata.id}}</p>
            <v-spacer></v-spacer><br>
            
            <v-spacer></v-spacer> 


           
       </v-col>
    
     </v-row>
    
     <v-row>
       <v-col class="text-center" >
          
           <v-btn 
            color="success"
            @click="backHome">
            Regresar
          </v-btn>
       </v-col>
     </v-row>
   </v-container>
 
 
</template>


<script>
 
import router from '../router'
  export default {
    name: 'registro',
   props: ['userdata'],
     data: () => ({
        
      }),

    methods:{
      backHome(){
          this.$router.push({ path: '/', replace: true });
      }
    },
    created() {
          this.userdata = this.$route.params.userdata;
           console.log('Params: ', this.$route.params);
    }
  }
</script>
