import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GraciasView from '../views/GraciasView.vue'
import RegistroView from '../views/RegistroView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
     meta: { transition: 'slide-left' },
  },
  ,
  {
    path: '/registro',
    name: "registro",
    props: true,  
    name: 'registro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
   // component: () => import(/* webpackChunkName: "about" */ '../views/GraciasView.vue')
 
   component: RegistroView
  },
  {
    path: '/gracias',
    name: 'gracias',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
   // component: () => import(/* webpackChunkName: "about" */ '../views/GraciasView.vue')
   component: GraciasView,
    meta: { transition: 'slide-left' },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
