<template>
   <v-container fill-height>
   
     <v-row>
       <v-col md="6"  class="mx-auto text-center my-5">
           <img src="https://www.acpblindaje.mx/sites/default/themes/acpblindaje/assets/img/logotipo-acp.png" alt="">
           <v-spacer></v-spacer>
          <h1 class="my-3 orp_title">Gracias por <br>   Participar</h1>
          <p>Visitanos en <a href="https://acpblindaje.mx" target="_blank">acpblindaje.mx</a></p>
          <v-spacer></v-spacer>
          <v-btn 
            color="success"
            @click="backHome">
            Regresar
          </v-btn>
       </v-col>
     </v-row>
   </v-container>
 
 
</template>


<script>
 

  export default {
    name: 'Gracias',

    components: {
     
    },
    methods:{
      backHome(){
          this.$router.push({ path: '/', replace: true });
      }
    }
  }
</script>
