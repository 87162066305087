<template>
  <v-app>
     
      <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition>
 
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
   metaInfo: {
    title: 'Acp Blindaje Sorteo',
    titleTemplate: '%s | Acp Blindaje Sorteo',
    htmlAttrs: {
      lang: 'es-MX'
    },
    meta: [
      { charset: 'utf-8' },
       { name: 'title', content: 'Acp  Blindaje' },
      { name: 'description', content: 'Sorteo ACp Blindaje' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { property: 'og:type', content: 'website' }

    ]
  },
};
</script>


<style lang="less">
  h1.orp_title{
    text-transform: uppercase;
    font-size: 2rem!important;
  }
</style>